var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver"},[_c('b-row',[_c('b-col',{attrs:{"lg":"5"}},[_c('h3',[_vm._v("Afiliación")])]),_c('b-col',{attrs:{"lg":"7"}},[_c('b-row',[_c('b-col',{attrs:{"sm":6}},[_c('b-form-group',{attrs:{"label":"Titularidad *"}},[_c('validation-provider',{attrs:{"name":"Titularidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-1",attrs:{"options":[
                  { text: 'Titular', value: true },
                  { text: 'Dependiente', value: false } ],"name":"insuredType"},on:{"change":_vm.handleInsuredType},model:{value:(_vm.formData.insuredIsTitular),callback:function ($$v) {_vm.$set(_vm.formData, "insuredIsTitular", $$v)},expression:"formData.insuredIsTitular"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(!_vm.formData.insuredIsTitular)?_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Titular *"}},[_c('validation-provider',{attrs:{"name":"Titular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"fullname","filterable":false,"options":_vm.titularOptions,"reduce":function (item) { return item.id; },"placeholder":"Escribe el CI del Titular ej. 1234567-1K"},on:{"search":_vm.onSearchTitular},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.fullname)+" "),_c('code',{staticClass:"ml-1"},[_c('small',[_vm._v("CI: "+_vm._s(option.ci))])])]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.fullname)+" "),_c('code',{staticClass:"ml-1"},[_c('small',[_vm._v("CI: "+_vm._s(option.ci))])])]}}],null,true),model:{value:(_vm.formData.user_titular_id),callback:function ($$v) {_vm.$set(_vm.formData, "user_titular_id", $$v)},expression:"formData.user_titular_id"}},[_c('template',{slot:"no-options"},[_vm._v(" Escribe el CI del Titular ej. 1234567-1K..")])],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3040593467)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Parentesco *"}},[_c('validation-provider',{attrs:{"name":"Codigo Beneficiario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.filterRelationships,"reduce":function (item) { return item.id; },"label":"name","clearable":false},scopedSlots:_vm._u([{key:"search",fn:function(ref){
                var attributes = ref.attributes;
                var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":errors.length ? false : null}},'input',attributes,false),events))]}}],null,true),model:{value:(_vm.formData.relationship_type_id),callback:function ($$v) {_vm.$set(_vm.formData, "relationship_type_id", $$v)},expression:"formData.relationship_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1674527381)})],1)],1)],1):_vm._e(),(_vm.formData.insuredIsTitular)?_c('b-row',[_c('b-col',{attrs:{"sm":6}},[_c('b-form-group',{attrs:{"label":"Número de Asegurado *"}},[_c('validation-provider',{attrs:{"name":"Número de Asegurado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"state":errors.length ? false : null,"placeholder":"123456ABC"},model:{value:(_vm.formData.registration_code),callback:function ($$v) {_vm.$set(_vm.formData, "registration_code", $$v)},expression:"formData.registration_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3989375024)})],1)],1),_c('b-col',{attrs:{"sm":6}},[_c('b-form-group',{attrs:{"label":"Fecha de Afiliación *"}},[_c('validation-provider',{attrs:{"name":"Fecha de Afiliación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"placeholder":"123456ABC","type":"date"},model:{value:(_vm.formData.affiliation_date),callback:function ($$v) {_vm.$set(_vm.formData, "affiliation_date", $$v)},expression:"formData.affiliation_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4160524531)})],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }