var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver"},[_c('b-row',[_c('b-col',{attrs:{"lg":"5"}},[_c('h3',[_vm._v("Datos Empleador")]),_c('p',{staticClass:"mt-2"},[_vm._v(" Para verificar el estado de la empresa donde trabaja cuenta con un estado "),_c('code',[_vm._v("ACTIVO")]),_vm._v(", escribe el Código Patronal que se encuentra en el "),_c('code',[_vm._v("AVC-04")]),_vm._v(". ")]),_c('p',[_c('code',[_vm._v("Ej: 01 - 730 - 0000001 ")])]),_c('small',[_c('strong',[_vm._v("Nota:")]),_vm._v(" En este paso solo pueden registrarse los "),_c('strong',[_vm._v(" Asegurados Titulares.")])])]),_c('b-col',{attrs:{"lg":"7"}},[_c('b-form-group',{attrs:{"label":"Código Patronal","label-for":"employer_code"}},[_c('validation-provider',{attrs:{"name":"Código Patronal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"code","filterable":false,"options":_vm.employers,"reduce":function (item) { return item.code; },"placeholder":"ej. 11-222-1234567"},on:{"search":_vm.onSearchEmployer,"input":_vm.handleSelectEmployer},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option",fn:function(option){return [_c('code',[_vm._v(_vm._s(option.code))])]}}],null,true),model:{value:(_vm.formData.employer_code),callback:function ($$v) {_vm.$set(_vm.formData, "employer_code", $$v)},expression:"formData.employer_code"}},[_c('template',{slot:"no-options"},[_vm._v("No se encontraron resultados...")])],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Empleador"}},[_c('validation-provider',{attrs:{"name":"Empleador","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"readonly":""},model:{value:(_vm.formData.employer_name),callback:function ($$v) {_vm.$set(_vm.formData, "employer_name", $$v)},expression:"formData.employer_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Salario Actual (Bs.)"}},[_c('validation-provider',{attrs:{"name":"Salario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length ? false : null},model:{value:(_vm.formData.salary),callback:function ($$v) {_vm.$set(_vm.formData, "salary", $$v)},expression:"formData.salary"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Inicio de Relacion Laboral *"}},[_c('validation-provider',{attrs:{"name":"Inicio de Relacion Laboral","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date","state":errors.length ? false : null},model:{value:(_vm.formData.employer_date),callback:function ($$v) {_vm.$set(_vm.formData, "employer_date", $$v)},expression:"formData.employer_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Ocupación"}},[_c('validation-provider',{attrs:{"name":"profession"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null},model:{value:(_vm.formData.profession),callback:function ($$v) {_vm.$set(_vm.formData, "profession", $$v)},expression:"formData.profession"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }