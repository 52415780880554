var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver"},[_c('b-row',[_c('b-col',{attrs:{"lg":"5"}},[_c('h3',[_vm._v("Cuenta")])]),_c('b-col',{attrs:{"lg":"7"}},[_c('b-row',[_c('b-col',{attrs:{"sm":6}},[_c('b-form-group',{attrs:{"label":"Tipo de Cuenta *"}},[_c('validation-provider',{attrs:{"name":"Tipo de Cuenta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-1",attrs:{"options":[
                  { text: 'Nueva', value: true },
                  { text: 'Existente', value: false } ],"name":"accountType"},on:{"change":_vm.handleAccountType},model:{value:(_vm.formData.isNewAccount),callback:function ($$v) {_vm.$set(_vm.formData, "isNewAccount", $$v)},expression:"formData.isNewAccount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(!_vm.formData.isNewAccount)?_c('b-form-group',{attrs:{"label":" Usuario *"}},[_c('validation-provider',{attrs:{"name":"Usuario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"fullname","filterable":false,"options":_vm.users,"reduce":function (item) { return item.id; },"placeholder":"Buscar CI..."},on:{"search":_vm.onSearchUser},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.fullname)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.fullname)+" "),_c('code',{staticClass:"ml-1"},[_c('small',[_vm._v("CI: "+_vm._s(option.ci))])])]}}],null,true),model:{value:(_vm.formData.user_id),callback:function ($$v) {_vm.$set(_vm.formData, "user_id", $$v)},expression:"formData.user_id"}},[_c('template',{slot:"no-options"},[_vm._v("No se encontraron resultados...")])],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,533658588)})],1):_vm._e(),(_vm.formData.isNewAccount)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Correo Electronico *"}},[_c('validation-provider',{attrs:{"name":"Correo Electronico","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","state":errors.length ? false : null},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,83419406)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Contraseña"}},[_c('validation-provider',{attrs:{"name":"Contraseña","vid":"Password","rules":"min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"type":"password"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,319313966)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Confirma contraseña"}},[_c('validation-provider',{attrs:{"name":"Contraseña","rules":"confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"type":"password"},model:{value:(_vm.formData.password_confirmation),callback:function ($$v) {_vm.$set(_vm.formData, "password_confirmation", $$v)},expression:"formData.password_confirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4235960478)})],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }